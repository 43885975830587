<template>
	<div>
		<!-- <div class="page-top-box"> -->
		    <el-tabs v-model="activeName" @tab-click="clickEvent">
		        <el-tab-pane v-for="(item,index) in interval" :key="index" :label="tabName.get(item.timeInterval)" :name="item.timeInterval+''"></el-tab-pane>
		    </el-tabs>
		    <div v-if="intervalData" class="page-group-title">
		        <span>{{intervalData.startTime}} <template  v-if="intervalData.startTime != intervalData.endTime"> 至 {{intervalData.endTime}}</template></span>
		    </div>
		<!-- </div> -->
		<div class="page-content-box">
			<div id="chartHost"></div>
			<div class="markRed">注意：仅统计历史已支付的订单数量和销售额（无论订单之后是否取消）</div>
			<div class="page-list-box">
			    <el-table
			        :data="dataList"
			        header-row-class-name="page-list-table"
			        style="width: 100%"
			        v-loading="listLoading"
			        size="small"
			        stripe
			    >
					<el-table-column prop="date" label="日期"></el-table-column>
					<el-table-column prop="formatAmount" label="销售额"></el-table-column>
					<el-table-column prop="totalNum" label="售票数"></el-table-column>
					<el-table-column prop="orderNum" label="订单数"></el-table-column>
					<el-table-column fixed="right" label="操作" width="200">
						 <template slot-scope="scope">
						    <el-button
						        v-if="scope.row.date && $utils.have('/report/activityDateSales')"
						        type="text"
						        size="small"
								@click="$router.push(`/manage/dashboard/hostsales/list/${scope.row.date}`)"
						        >查看对应活动</el-button>
						</template>						
					</el-table-column>
				</el-table>
			</div>
			<div v-if="data" class="page-pagination-box">
			    <el-pagination
			        @size-change="handleSizeChange"
			        @current-change="getHostList"
			        :current-page.sync="params.pageNo"
			        :page-sizes="[15, 30, 50]"
			        :page-size.sync="params.pageSize"
			        layout="sizes, prev, pager, next"
			        :total="data.totalCount || 0">
			    </el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
import { Chart } from '@antv/g2';

export default {
    components: { },
    name: "dashboard",
    data() {
        return {
            dataLoading: false,
			listLoading: false,
            interval: [],
            activeName: '7',
            intervalData: null,
			params:{
			    pageNo:parseInt(this.$route.query.pageNo) || 1,
			    pageSize: parseInt(this.$route.query.pageSize) || 15,			    
			},
			data:null,
			dataList: null,
			chart:null,
			
        };
    },
    computed:{
        tabName(){
            return new Map([
                [-1,'昨日'],[1,'今日'],[7,'最近7天'],[30,'最近30天'],[90,'最近90天'],[180,'最近180天'],[360,'最近360天']
            ])
        }
    },
    created(){
        this.getInterval();		
    },
	
	watch:{
	    intervalData(v1,v2){
	        if(v1 != v2){
	            this.gethostChart();
				this.getHostList();
	        }
	    }
	},
    methods:{
        //获取时间间隔
        getInterval(){           
            this.$request({
                url: "/report/interval",
                method: "POST",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.interval = result;
                        this.intervalData = this.interval.find(v => v.timeInterval == this.activeName);
						//this.gethostChart();
						this.getHostList()
						
                    } else {
                        this.$message.error(msg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        clickEvent(tab){
            this.intervalData = this.interval.find(v => v.timeInterval == tab.name);
        },
		//主办活动销售曲线图
		gethostChart(){			
			this.$request({
			    url: "/report/activitySales",
			    method: "POST",
				data: {timeInterval: this.intervalData.timeInterval}
			})
			    .then((res) => {
			        const { state, msg, result } = res.data;
			        if (state == 1 && result) {
			            this.data = result;
						this.$nextTick(() => {
						    this.drawChart();						    
						})
						
			        } else {
			            this.$message.error(msg || "请求失败");
			        }
			    })
			    .catch(() => {
			        this.$message.error("请求错误");
			    })
			    .finally(() => {
			        this.dataLoading = false;
			    });
		},
		
		drawChart(){			
			
			if( this.chart ) this.chart.destroy();
			this.chart = new Chart({
			    container: 'chartHost',
			    autoFit: true,
			    height: 500,
			});		
			
			this.chart.data(this.data.lineData);
			
			var dataChart = []
			this.data.lineData.forEach((tab, index) => {
				dataChart.push({
					value: parseInt(tab.销售额),
					value1: tab.售票数,
					
				});
			});
			
			
			var totalAmount = dataChart[0].value;
			var totalOrderNum = dataChart[0].value1;
			
			
			for (var i = 1; i < dataChart.length; i++) {
			  if (dataChart[i].value > totalAmount) {
			    totalAmount = dataChart[i].value;
			  }
			  if (dataChart[i].value1 > totalOrderNum) {
			    totalOrderNum = dataChart[i].value1;
			  }
			}
			
			console.log(totalAmount);
			console.log(totalOrderNum);

			this.chart.scale({
			  售票数: {
			    min: 0,
			    max: totalOrderNum,
			  },
			  销售额: {
			    min: 0,
			    max: totalAmount,
			  }
			});
			this.chart.legend({
			  custom: true,
			  items: [
			    { value: '销售额', name: '销售额', marker: { symbol: 'square', style: { fill: '#3182bd', r: 5 } } },
			    { value: '售票数', name: '售票数', marker: { symbol: 'hyphen', style: { stroke: '#fdae6b', r: 5, lineWidth: 3 } } }
			  ],
			});
			this.chart.axis('售票数', {
			  grid: null,
			  title: {
			    style: {
			      fill: '#fdae6b'
			    }
			  },
			  label: {
			    style: {
			      fill: '#fdae6b'
			    }
			  }
			});
			this.chart.axis('销售额', {
			  title: {}
			});
			this.chart.tooltip({
			  shared: true,
			});
			this.chart.interval()
			  .position('name*销售额')
			  .color('#3182bd')
			 
			this.chart.line()
			  .position('name*售票数')
			  .color('#fdae6b')
			  .size(3)
			  .shape('smooth');
			this.chart.point()
			  .position('name*售票数')
			  .color('#fdae6b')
			  .size(3)
			  .shape('circle');
			
				
			this.chart.interaction('active-region');
			this.chart.removeInteraction('legend-filter'); // 自定义图例，移除默认的分类图例筛选交互
			this.chart.render();
		},
		//主办销售活动线图下面的列表
		getHostList(){
			this.$request({
			    url: "/report/activitySalesPage",
			    method: "POST",				
				data: {...this.params,timeInterval: this.intervalData.timeInterval},
			})
			    .then((res) => {
			        const { state, msg, result } = res.data;
			        if (state == 1 && result) {
					   this.data = result
			           this.dataList = result.result
			        } else {
			            this.$message.error(msg || "请求失败");
			        }
			    })
			    .catch(() => {
			        this.$message.error("请求错误");
			    })
			    .finally(() => {
			        
			    });
		},
		//选择pageSize
		handleSizeChange(e) {
		    this.params.pageNo = 1;
		    this.params.pageSize = e;
		    this.getHostList();
		},
		
    }
};
</script>

<style lang="scss" scoped>
// .page-top-box{
// 	background: #fff;
// }
// .page-tab-box{
//     margin: -20px -20px 20px -20px;
//     padding: 0 20px;
//     border-bottom: 1px #E4E7ED solid;
//     ::v-deep .el-tabs__header{
//         border-bottom: 0;
//         margin: 0;
//     }
// }
.data-group{
    > ::v-deep .el-card__header{
        font-size: 16px;
        line-height: 22px;
        a{
            font-size: 14px;
        }
    }
}
.markRed{
	padding:40px 0 0 0;
	color: #f00;
}
</style>